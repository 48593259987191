import { ReverseMap } from '../types/utility'
import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'

/**
 * "[Alexa] noticed in some of our purely JS codebases,
 * we have a tendency to rely heavily on .hasOwnProperty
 * and then doing subsequent checks. This is fairly fragile
 * in some regards due to a number of issues about properties
 * being present and undefined, and so forth"
 * @param obj
 * @param prop
 * @param propTypeOrResolver predicate function to use for typechecking,
 * or a string representing the result of the typeof operator being applied,
 * such as 'string'
 */
export function hasPropWithType(obj, prop, propTypeOrResolver): boolean {
  const propExists = obj && obj[prop] !== undefined && obj[prop] !== null
  if (!propExists) {
    return false
  }
  if (typeof propTypeOrResolver === 'function') {
    return propTypeOrResolver(obj[prop])
  } else if (typeof propTypeOrResolver === 'string') {
    return typeof obj[prop] === propTypeOrResolver
  } else {
    console.error('No prop type to check against was provided to hasPropWithType')
    return false
  }
}

/**
 * Removes the prop of an immutable object,
 * returning a new object.
 *
 * @param obj the object from where to remove the prop
 * @param prop the property or properties to remove
 */
export function removePropFromObject<T>(obj: T, prop: string | string[]): T {
  const newObj = Object.keys(obj).reduce((accObj, key) => {
    if (Array.isArray(prop) ? !prop.includes(key) : key !== prop) {
      accObj[key] = obj[key]
    }
    return accObj
  }, {} as T)

  return newObj
}

/**
 * Reverses the keys of an object with their corresponding value.
 *
 * `{ foo: 'bar' }` => `{ bar: 'foo' }`
 *
 * @param obj the object where keys will be reversed with values.
 */
export function invertBy<T>(obj: T): ReverseMap<T> {
  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key]
    acc[val] = key
    return acc
  }, {} as ReverseMap<T>)
}

/**
 * Nullifies an empty object,
 * e.g. `{}` => null
 *
 * @param obj the object to check
 */
export function nullifyEmptyObject<T>(obj: T): T | null {
  return Object.keys(obj).length ? obj : null
}

/**
 * Deep transforms an object by
 * removing invalid values.
 * Capable of nullifying empty objects.
 *
 * @param obj the object to deep transform
 * @param invalidValues the invalid values to exclude
 * @param nullify option to nullify
 */
export function deepExcludeValues<T>(
  obj: T,
  invalidValues: ReadonlyArray<number | string | boolean | undefined | null>,
  nullify = false
): T | null {
  if (typeof obj !== 'object') {
    return obj
  }
  const cleanObj = Object.keys(obj)
    .filter(k => !invalidValues.includes(obj[k]))
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? {
              ...newObj,
              [k]: Array.isArray(obj[k])
                ? obj[k].map(objChild => deepExcludeValues(objChild, invalidValues, nullify))
                : deepExcludeValues(obj[k], invalidValues, nullify),
            }
          : { ...newObj, [k]: obj[k] },
      {} as T
    )
  return nullify ? nullifyEmptyObject(cleanObj) : cleanObj
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray(myArray: any[], chunkSize: number) {
  let index = 0
  const arrayLength = myArray.length
  const tempArray: any[] = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize)

    tempArray.push(myChunk)
  }

  return tempArray
}

/**
 * Transforms the keys of an object using a provided transformation function.
 *
 * @param {T} obj - The original object whose keys need to be transformed.
 * @param {(key: string) => string} transformFn - A function that takes a key as a string and returns a transformed key as a string.
 * @returns {Record<string, T[keyof T]>} - A new object with transformed keys.
 */
export function transformKeys<T extends Record<string, any>>(
  obj: T,
  transformFn: (key: string) => string
): Record<string, T[keyof T]> {
  const transformedObj: Record<string, T[keyof T]> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = transformFn(key);

      transformedObj[newKey] = obj[key];
    }
  }

  return transformedObj;
}

export function difference(object: any, base: any) {
  return transform(object, (result: any, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value
    }
  })
}

export function toNumber(value: any) {
  return value === undefined || value === null ? value : +value
}
